// extracted by mini-css-extract-plugin
export var backgroundAnimation = "halloween-layout-module--backgroundAnimation--62706";
export var banner = "halloween-layout-module--banner--d238f";
export var contact = "halloween-layout-module--contact--66a29";
export var container = "halloween-layout-module--container--a9a98";
export var content = "halloween-layout-module--content--d97b9";
export var errorBlink = "halloween-layout-module--error-blink--3066b";
export var hasHeader = "halloween-layout-module--has-header--8efc2";
export var header = "halloween-layout-module--header--9945d";
export var headerContent = "halloween-layout-module--header-content--9f037";
export var hideBanner = "halloween-layout-module--hide-banner--9db2a";
export var layout = "halloween-layout-module--layout--99aa7";
export var left = "halloween-layout-module--left--5b149";
export var mainLayout = "halloween-layout-module--main-layout--68ccf";
export var pear = "halloween-layout-module--pear--bddce";
export var right = "halloween-layout-module--right--44ec4";
export var thanks = "halloween-layout-module--thanks--38c2b";
export var web = "halloween-layout-module--web--615c8";