import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
    layout,
    hideBanner,
    container,
    content,
    header,
    hasHeader,
    thanks,
    headerContent,
    mainLayout,
    contact,
    web,
    pear,
    left,
    right,
    banner,
} from './halloween-layout.module.scss';
import { grid } from '../styles/grid.module.scss';

import staticFiles from '../config/static-files';

import MainLayout, { IMainLayoutProps } from './main-layout';
import ContestThankYou, { IContestThankYouProps } from '../components/organisms/contest-thank-you';
import ContestContact from '../components/organisms/contest-contact';

interface IHalloweenLayout extends IMainLayoutProps {
    pageType: 'main' | 'thank-you' | 'winner' | 'expired' | 'closed';
    children?: React.ReactNode;
    headerProps?: IContestThankYouProps;
    showSideImages?: boolean;
}

const webImg = '../assets/images/halloween/cherry-web.png';
const pearImg = '../assets/images/halloween/pear.png';
const treesLeftImg = '../assets/images/halloween/trees-left.png';
const treesRightImg = '../assets/images/halloween/trees-right.png';

const HalloweenLayout: React.FC<IHalloweenLayout> = ({
    children,
    pageType,
    className = '',
    headerProps,
    showSideImages = false,
    ...rest
}) => {
    return (
        <MainLayout
            {...rest}
            className={`${layout} ${headerProps ? hasHeader : ''}`}
            bannerClass={banner}
            containerClassName={`${mainLayout} ${pageType === 'closed' ? hideBanner : ''}`}
        >
            <div className={`${headerProps ? grid : ''} ${header}`}>
                <div className={headerContent}>
                    {headerProps && <ContestThankYou {...headerProps} className={thanks} />}
                </div>
            </div>
            <div className={`${container} ${grid}`}>
                <div className={`${className} ${content}`}>{children}</div>
                <ContestContact
                    className={contact}
                    regulationsUrl={staticFiles.halloweenRegulations}
                />
            </div>
            {pageType === 'thank-you' && (
                <>
                    <StaticImage src={webImg} alt={''} className={web} />
                    <StaticImage src={pearImg} alt={''} className={pear} />
                </>
            )}
            {(pageType === 'expired' || pageType === 'closed' || pageType === 'winner') && (
                <>
                    <StaticImage src={treesLeftImg} alt={''} className={left} />
                    <StaticImage src={treesRightImg} alt={''} className={right} />
                </>
            )}
        </MainLayout>
    );
};

export default HalloweenLayout;
